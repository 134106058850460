@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-popup {
    &:focus-visible {
        // Fix weird safari bug where the popup has a flickering focus ring
        // the first time the popup opens.
        outline: 0 transparent !important;
    }

    &__wrapper {
        position: relative;
        width: 100%;
        background-color: $ngr-color-white;
        z-index: 1;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        max-height: calc(100vh - 10px - max(var(--safe-area-top), 8px));
        display: flex;
        flex-direction: column;
        overflow: clip;
        flex: auto;

        &--fullscreen {
            margin-top: calc(10px + max(var(--safe-area-top), 8px));
            height: calc(100vh - 10px - max(var(--safe-area-top), 8px));
        }

        &--touching {
            transition: none;
        }

        &--force-fullscreen {
            height: 100vh;
            max-height: 100vh;
            padding-top: calc(16px + var(--safe-area-top));
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        &--red {
            background-color: $ngr-meny-primary-base;
        }
    }
}
