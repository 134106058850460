@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.personal-campaigns {
    &__card--standalone {
        width: 100% !important;
    }
}

.personal-campaign-empty {
    display: grid;
    grid-template-columns: 60px 1fr;
    gap: 16px;
    padding: 1rem;
    overflow: hidden;
    background-color: $ngr-color-gray-5;
    border-radius: $ngr-card-border-radius;
    align-items: center;

    &__icon {
        width: 60px;
        height: 60px;
        background-color: $ngr-button-color-foreground--primary;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }
}
