@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-status {
    position: fixed;
    left: 0;
    right: 0;
    backface-visibility: hidden;
    overflow: hidden;
    height: auto;
    bottom: $app-footer-height + $app-footer-padding-bottom;
    display: flex;
    align-items: flex-end;


    &.slide-up {
        &-enter {
            transform: translateY(100vh);

            &-active {
                // see https://github.com/reactjs/react-transition-group/issues/10
                transition: transform $app-animation-duration ease-out;
                transform: translateY(0);
            }
        }

        &-exit {
            transform: translateY(0);

            &-active {
                // see https://github.com/reactjs/react-transition-group/issues/10
                transition: transform $app-animation-duration ease-out;
                transform: translateY(100vh);
            }
        }
    }
}
