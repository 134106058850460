@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-navigation {
    user-select: none;
    position: relative;
    background-color: $ws-secondary-color;

    &__network-status {
        @include ngr-font-size(kilo);

        position: absolute;
        overflow: hidden;
        bottom: 100%;
        left: 0;
        right: 0;
        background-color: $ws-dark-bg-color;
        color: $ws-white;
        text-align: center;
        font-weight: bold;
        padding: 14px 0;
        animation: network-status-in $ws-transition-duration both;
        z-index: 10;
    }

    &__menu {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &__debug-link {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 11;
        right: 0;
        bottom: 100%;
        background-color: $ngr-meny-support01-light-1;
        border-top-left-radius: 18px;
        height: 32px;
        width: 32px;
    }

    &__pride-banner {
        border-top: solid;
        border-image-slice: 5;
        border-image-source: linear-gradient(135deg, #FF3636 13.28%, #FFA301 13.28% 27.57%, #FAEB02 27.57% 41.84%, #148924 41.84% 56.12%, #074AF9 56.12% 69.40%, #6D0D90 69.40% 100%);
        border-width: 8px;
    }
}

@keyframes network-status-in {
    from {
        height: 0;
        padding: 0;
    }

    to {
        height: 1.35 * 14px + 28px;
        padding: 16px;
    }
}
