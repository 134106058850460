@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.personal-campaign {
    width: 100%;

    &__background-icon {
        width: 100%;
        display: block;

        svg {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    &__background {
        height: 194px;
        width: 100%;
        background-color: $ngr-button-color-background--primary;

        &--trumf {
            background-color: $ngr-meny-trumf-blue;
        }
    }

    &__top {
        position: relative;
        margin-top: -1px;
    }

    &__icon {
        width: 126px;
        height: 126px;
        background-color: $ngr-button-color-foreground--primary;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 1rem 3rem;
    }

    &__title {
        font-weight: bold;

        @include ngr-font-size(hotel);
    }
}
